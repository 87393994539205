<template>
  <div>
    <b-row>
      <b-col>
        <h1>User Profile</h1>
      </b-col>
      <b-col md="2">
        <b-button
          v-if="readonly && UserType !== 'Client'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click.prevent="readonly = false"
        >
          Edit Profile
        </b-button>
      </b-col>
    </b-row>

    <hr>

    <b-row>
      <b-col md="9">
        <div class="white-bg-container">
          <b-row>
            <b-col>
              <b-form-group
                label="First Name"
                label-for="firstName"
              >
                <b-form-input
                  id="firstName"
                  v-model="userObj.FirstName"
                  placeholder=""
                  name="firstName"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Middle Name"
                label-for="middleName"
              >
                <b-form-input
                  id="middleName"
                  v-model="userObj.MiddleName"
                  placeholder=""
                  name="middleName"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Last Name"
                label-for="lastName"
              >
                <b-form-input
                  id="lastName"
                  v-model="userObj.LastName"
                  placeholder=""
                  name="lastName"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <b-form-group
                label="Title"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="userObj.Title"
                  placeholder=""
                  name="title"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Department"
                label-for="department"
              >
                <b-form-input
                  id="department"
                  v-model="userObj.Department"
                  placeholder=""
                  name="department"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="userObj.EMail"
                  placeholder=""
                  name="email"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <b-form-group
                label="Street Address"
                label-for="streetAddress"
              >
                <b-form-input
                  id="streetAddress"
                  v-model="userObj.Address"
                  placeholder=""
                  name="streetAddress"
                  :readonly="readonly"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="City"
                label-for="city"
              >
                <b-form-input
                  id="city"
                  v-model="userObj.City"
                  placeholder=""
                  name="city"
                  :readonly="readonly"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="State"
                label-for="state"
              >
                <v-select
                  id="state"
                  v-model="userObj.State"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="StatesList"
                  placeholder=""
                  name="state"
                  :disabled="readonly"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Zip"
                label-for="zip"
              >
                <ZipInput
                  id="zip"
                  v-model="userObj.PostalCode"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Phone"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="userObj.Phone"
                  v-mask="'(###) ###-####'"
                  placeholder=""
                  name="phone"
                  :readonly="readonly"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Ext"
                label-for="ext"
              >
                <b-form-input
                  id="ext"
                  v-model="userObj.PhoneExt"
                  v-mask="'(###) ###-####'"
                  placeholder=""
                  name="ext"
                  :readonly="readonly"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Mobile/SMS"
                label-for="mobile"
              >
                <b-form-input
                  id="mobile"
                  v-model="userObj.MobileSMS"
                  v-mask="'###-###-####'"
                  placeholder=""
                  name="mobile"
                  :readonly="readonly"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <VehicleInfo
              v-for="(vehicle, index) of userObj.UserOwnVehicles"
              :key="vehicle.ID"
              :vehicle="vehicle"
              :index="index"
              :isReadonly="true"
            />
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="!readonly"
      class="pt-2"
    >
      <b-col>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="mr-2"
          type="reset"
          variant="danger"
          @click="resetForm()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          :disabled="inProcess"
          @click="saveForm()"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BButton, BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ZipInput from "@core/components/zipInput/ZipInput.vue";
import VehicleInfo from "@/views/admin/components/VehicleInfo";
import {mapMutations} from "vuex";

const apiService = new APIService();

export default {
  components: {
    ZipInput,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    VehicleInfo,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      UserID: "",
      userObj: {},
      UserType: "",

      StatesList: [
        'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
        'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME',
        'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM',
        'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'
      ],
      readonly: true,
      inProcess: false,
    }
  },
  created() {
    this.getUser();
    this.UserType = localStorage.getItem("userType");
  },
  methods: {
    ...mapMutations({
      setUserToEdit: "users/setUserToEdit",
    }),
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "User Profile",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
    getUser() {
      apiService
          .get("user/profile")
          .then( res => {
            this.userObj = res.data;
            this.setUserToEdit(this.userObj)
          })
    },
    saveForm() {
      this.inProcess = true;
      const formData = {
        "StreetAddress": this.userObj.Address,
        "Phone": this.userObj.Phone,
        "PhoneExt": this.userObj.PhoneExt,
        "City": this.userObj.City,
        "State": this.userObj.State,
        "MobileSMS": this.userObj.MobileSMS,
        "PostalCode": this.userObj.PostalCode,
      };
      apiService
          .post("user/edit-profile", formData)
          .then(res => {
            this.inProcess = false;
            if (res) {
              this.showToast('success', 'top-center', 4000, 'Changes to your user profile have been saved.');
              if (res.data.success) {
                this.$router.push('/cases');
              } else {
                this.showToast('warning', 'top-center', 5000, res.data.message);
              }
            }
          })
    },
    resetForm() {
      this.$router.push('/cases');
    },
  }
}
</script>

<style scoped>

</style>